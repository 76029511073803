import React from "react";
import { PageLayout, ContactForm } from "@bluefin/components";
import { Grid, Header, Image, p } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class CareersPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness } = data;

    return (
      <WebsiteLayout>
        <PageLayout hero={false} subfooter={false} className={"careers-page"}>
          <Grid stackable={true} columns={1} centered={true}>
            <Grid.Column textAlign={"center"} width={12}>
              <Header as={"h1"}>Careers</Header>
              <Grid
                stackable={true}
                verticalAlign={"middle"}
                textAlign={"left"}
                centered={true}
                column={"2"}
              >
                <Grid.Row stretched={true}>
                  <Grid.Column width={8} textAlign={"center"}>
                    <Image
                      size={"medium"}
                      centered={true}
                      src={
                        "https://fisherman.gumlet.io/public/2e1376fa-0d04-49e4-bea2-1d9351a37203/Careers.jpg"
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8} textAlign={"left"}>
                    <Header as={"h3"}>Kitchen Staff</Header>
                    <p>
                      Responsible for making pizzas, cleaning, register, and
                      general duties.
                    </p>
                    <Header as={"h3"}>Bartender</Header>
                    <p>
                      Must be 21 to apply and have previous experience in the
                      service industry. Certification training will be required.
                    </p>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <p>
                If you are interested in working for us please click the link to
                fill out an application and state which job you are applying
                for.
              </p>
            </Grid.Column>
            <Grid.Column textAlign={"left"} width={8}>
              <ContactForm
                constructMessage={({
                  name,
                  email,
                  phone,
                  address,
                  birthdate,
                  workExperience,
                  hoursAvailable,
                  daysAvailable,
                  position,
                }) =>
                  `Someone has reached out to you via your website hiring form: Name: ${name}, Email: ${email}, Phone: ${phone}, Address: ${address}, Date of Birth: ${birthdate}, Previous Work Experience: ${workExperience}, Hours Available: ${hoursAvailable}, Days Available: ${daysAvailable}, Position: ${position}`
                }
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "birthdate",
                    displayLabel: "Date of Birth",
                    placeholder: "Date of Birth",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    displayLabel: "Previous Work Experience",
                    label: "workExperience",
                    placeholder: "Previous Work Experience",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "hoursAvailable",
                    displayLabel: "Hours Available",
                    type: "select",
                    required: true,
                    options: [
                      { label: "AM Shifts", value: "AM Shifts" },
                      { label: "PM Shifts", value: "PM Shifts" },
                      { label: "No preference", value: "No Preference" },
                    ],
                  },
                  {
                    label: "daysAvailable",
                    placeholder: "Days Available",
                    type: "multiSelect",
                    required: true,
                    options: [
                      { label: "Monday", value: "Monday" },
                      { label: "Tuesday", value: "Tuesday" },
                      { label: "Wednesday", value: "Wednesday" },
                      { label: "Thursday", value: "Thursday" },
                      { label: "Friday", value: "Friday" },
                      { label: "Saturday", value: "Saturday" },
                      { label: "Sunday", value: "Sunday" },
                    ],
                  },
                  {
                    label: "position",
                    placeholder: "Position",
                    type: "multiSelect",
                    required: true,
                    options: [
                      { label: "Bartender", value: "Bartender" },
                      { label: "Kitchen Staff", value: "Kitchen Staff" },
                    ],
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                destinationEmail={"info@benniespizzapub.net"}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      primaryLocation {
        _id
      }
      _id
      type
    }
  }
`;
